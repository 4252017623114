import _ from 'lodash';
import * as React from 'react';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import RequestResponse from "./RequestResponse";

function HttpLogMain({ }) {
    let { remote, version } = useParams();
    let navigate = useNavigate();

    let [requestList, setRequestList] = React.useState([]);
    let [responseMap, setResponseMap] = React.useState(new Map());
    let [connected, setConnected] = React.useState(false);

    React.useEffect(() => {
        let socket = null;
        let close = false;
        function connect() {
            if (close) {
                return;
            }
            if (socket) {
                socket.close();
            }
            console.log("socket connect to " + remote);
            socket = new WebSocket(remote);
            socket.onopen = function () {
                setConnected(true);
                console.log("socket.onopen");
            };
            socket.onmessage = function (e) {
                let request = JSON.parse(e.data);

                let isResponse = !!request._requestId;
                if (request._version === "v2") {
                    isResponse = request._type === "response";
                }

                if (isResponse) {
                    setResponseMap(prev => {
                        let map = new Map(prev);
                        let response = request;
                        console.log(response);
                        map.set(response._requestId, response);
                        return map;
                    });
                } else {
                    setRequestList(prev => {
                        return prev.concat(request);
                    })
                }
            };
            socket.onclose = function () {
                setConnected(false);
                console.log("socket closed");
                if (!close) {
                    setTimeout(() => {
                        connect();
                    }, 1000);
                }
            };
            socket.onerror = function (e) {
                console.log("socket error");
            }
        }

        connect();
        setRequestList([]);
        setResponseMap(new Map());

        document.title = `HttpLog ${remote}`;
        return () => {
            close = true;
            console.log('组件卸载了');
            socket.close();
        }
    }, [remote, version]);

    let firstRequestTime = requestList.length > 0 ? requestList[0]._ts : 0;
    let lastRequestTime = requestList.length > 0 ? requestList[requestList.length - 1]._ts : 0;
    let speed = parseInt(responseMap.size / ((lastRequestTime - firstRequestTime) / 1000) * 60 * 100) / 100;
    _.isNaN(speed) && (speed = 0);
    return <div style={{ padding: 50, position: "absolute", left: 0, right: 0, top: 30, bottom: 30 }}>
        {remote} version:{version} 数量: {requestList.length} 状态:{connected ? <span style={{ color: "#29A634" }} >已连接</span> : <span style={{ color: "#CD4246" }}>连接中</span>} 速度:{speed}个请求/分钟
        <div style={{ position: "absolute", left: 50, right: 0, top: 80, bottom: 0, overflowY: "scroll" }}>
            {
                requestList.map((request, index) => {
                    return <RequestResponse key={request._requestId} request={request} response={responseMap.get(request.id || request._requestId)} />
                })
            }
        </div>
    </div>
}

export default React.memo(HttpLogMain);