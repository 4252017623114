import ReactJson from 'react-json-view'
import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
const cheerio = require('cheerio');
let axios = require('axios');
let jsonpAdapter = require('axios-jsonp');
var formatter = require('html-formatter');



export default function ({ }) {
    let { remote, version, key } = useParams();

    let [data, setData] = React.useState("downloading....");
    let [err, setErr] = React.useState("");

    React.useEffect(() => {
        (async () => {
            let res = await axios({
                url: `${remote}/getFileAsString`,
                params: {
                    key: key
                },
                adapter: jsonpAdapter
            })
            let data = res.data.data;

        })();
    }, [key]);

    return <div style={{ padding: 50 }}>
        <div>{key}</div>
        <hr />
        <div>

        </div>
    </div>
}