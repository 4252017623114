
import React from "react";

export default function Pager({ page, total, onPageChange }) {

    let [inputPage, setInputPage] = React.useState(page);
    let pages = [];
    //附近的10页
    for (let i = page - 5; i <= page + 5; i++) {
        if (i >= 1 && i <= total) {
            pages.push(i);
        }
    }

    return <div style={{ display: "block", float: "left", width: "50%", marginTop: 10 }}>
        <div style={{ display: "flex" }}>
            <button onClick={() => onPageChange(1)} disabled={page === 1}>{"<<"}</button>
            <button onClick={() => onPageChange(page - 1)} disabled={page === 1}>{"<"}</button>
            {
                pages.map(p => {
                    return <button key={p} onClick={() => onPageChange(p)} disabled={p === page}>{p}</button>
                })
            }
            <button onClick={() => onPageChange(page + 1)} disabled={page === total}>{">"}</button>
            <button onClick={() => onPageChange(total)} disabled={page === total}>{">>"}</button>
            自定义跳转到第<input type="number" style={{ width: 50 }} value={inputPage} onChange={(e) => {
                setInputPage(e.target.value);
            }} />页

            <button onClick={() => {
                let p = parseInt(inputPage);
                if (p >= 1 && p <= total) {
                    onPageChange(p);
                }
            }
            }>跳转</button>
        </div>
    </div>

}