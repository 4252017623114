
import * as React from 'react';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import FileList from "./FileList";
import { Breadcrumb, Breadcrumbs, Button, Classes, ControlGroup, Drawer, FormGroup, InputGroup } from '@blueprintjs/core';

let axios = require('axios');
let jsonpAdapter = require('axios-jsonp');

// Somewhere in your `index.ts`: 
let timer = null;
let callId = 0;

function SFSMain({ }) {
    let navigate = useNavigate();

    if (!window.location.hash.startsWith('#/')) {
        navigate("/#" + window.location.pathname);
        window.location.reload();
    }

    let { remote, version, currentPath, currentSearch, type, key } = useParams();
    let [files, setFiles] = React.useState([]);
    let [loading, setLoading] = React.useState(true);
    let [init, setInit] = React.useState(true);
    let [searchParams, setSearchParams] = useSearchParams();
    function apiHttpCall(api, params, callback, timeout = 1000) {
        clearTimeout(timer);
        callId++;
        setLoading(true);
        let thisCallId = callId;
        timer = setTimeout(async () => {
            let res = await axios({
                url: `${remote}/${api}`,
                params: params,
                adapter: jsonpAdapter
            })
            if (thisCallId === callId) {
                setLoading(false);
                callback(res.data);
            }
        }, timeout);
    }

    let realSearch = currentSearch.substring(1);

    function startSearch() {
        if (realSearch) {
            apiHttpCall("searchFiles", { search: realSearch }, (data) => {
                setFiles(data);
            }, init ? 0 : 1000);
        } else {
            apiHttpCall("listFiles", { parentPath: currentPath }, (data) => {
                setFiles(data);
            }, 0);
        }
        setInit(false);
    }
    React.useEffect(() => {
        startSearch();
        document.title = `SFS ${remote}`;
    }, [currentPath, realSearch]);

    let allPath = currentPath.split("/").filter(s => s !== "");

    let breadcrumbsList = [{
        icon: "folder-open", text: "[根目录]", path: ""
    }];

    function renderBreadcrumb(props) {
        return <Breadcrumb {...props} onClick={(e) => {
            console.log(props.path)
        }} />
    }

    breadcrumbsList = [...breadcrumbsList, ...allPath.map((p, index) => {
        function toPath() {
            let str = "";
            for (let i = 0; i <= index; i++) {
                str += ("/" + allPath[i]);
            }
            return str;
        }
        return {
            icon: "folder-open", text: p, path: toPath()
        }
    })];

    return <div style={{ padding: 50, position: "absolute", left: 0, right: 0, top: 30, bottom: 30 }}>
        <p>
            api address: {remote} version: {version}
        </p>
        <hr />
        <FormGroup
            label="文件名搜索 (文件多时性能较差) (输入停止1S后开始搜索)"
            labelFor="fileName"
        >
            <ControlGroup
                vertical={false}>
                <InputGroup id="fileName" style={{ width: 380 }} placeholder="文件名" value={realSearch} onChange={e => {
                    navigate(`/SFS/${encodeURIComponent(remote)}/${encodeURIComponent(version)}/${encodeURIComponent(currentPath)}/${encodeURIComponent(" " + e.target.value)}/`);
                }} />
                <Button icon="refresh" onClick={() => startSearch()} />
            </ControlGroup>
        </FormGroup>
        {currentPath}
        <Breadcrumbs items={breadcrumbsList} currentBreadcrumbRenderer={renderBreadcrumb} />
        <div style={{ position: "absolute", left: 50, right: 0, top: 205, bottom: 30 }}>
            <div>
                {!loading && `一共${files.length}个文件`}
            </div>
            <FileList files={files} loading={loading} />
        </div>
        {
            <Drawer icon="document"
                className={Classes.DARK}
                onClose={() => {
                    navigate(`/SFS/${encodeURIComponent(remote)}/${encodeURIComponent(version)}/${encodeURIComponent(currentPath)}/${encodeURIComponent(" " + realSearch)}/`)
                }}
                autoFocus={true} isOpen={!!type} title={key} size="100%">
                <Outlet />
            </Drawer>
        }
    </div>
}

export default React.memo(SFSMain);