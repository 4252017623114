import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as echarts from 'echarts'
import { FormGroup, HTMLSelect, Label, Spinner } from '@blueprintjs/core';
import { Select2 } from '@blueprintjs/select';
import moment from 'moment';

let axios = require('axios');
let jsonpAdapter = require('axios-jsonp');

const TIME_GRANULARITY_CONFIGS = [
    {
        value: 1000,
        label: "1秒"
    },
    {
        value: 5000,
        label: "5秒"
    },
    {
        value: 10000,
        label: "10秒"
    },
    {
        value: 30000,
        label: "30秒"
    },
    {
        value: 60000,
        label: "1分钟"
    },
    {
        value: 300000,
        label: "5分钟"
    },
    {
        value: 600000,
        label: "10分钟"
    },
    {
        value: 1800000,
        label: "30分钟"
    },
    {
        value: 3600000,
        label: "1小时"
    },
    {
        value: 3600000,
        label: "3小时"
    },
    {
        value: 21600000,
        label: "6小时"
    },
    {
        value: 43200000,
        label: "12小时"
    },
    {
        value: 86400000,
        label: "1天"
    }
]

const UPDATE_FREQUENCY_CONFIGS = [
    {
        value: 1000,
        label: "1秒"
    },
    {
        value: 5000,
        label: "5秒"
    },
    {
        value: 10000,
        label: "10秒"
    },
    {
        value: 30000,
        label: "30秒"
    },
    {
        value: 60000,
        label: "1分钟"
    },
    {
        value: 300000,
        label: "5分钟"
    },
    {
        value: 600000,
        label: "10分钟"
    }
]

const QUERY_COUNT_CONFIGS = [
    {
        value: 50,
        label: "50条"
    },
    {
        value: 100,
        label: "100条"
    },
    {
        value: 200,
        label: "200条"
    },
    {
        value: 500,
        label: "500条"
    }
]

export default function Main() {
    let { remote, version } = useParams();
    //更新频率
    let [updateFrequency, setUpdateFrequency] = React.useState(10000);
    //时间粒度
    let [timeGranularity, setTimeGranularity] = React.useState(60000);

    //查询数据的条数
    let [queryCount, setQueryCount] = React.useState(100);

    let [loading, setLoading] = React.useState(true);

    //TODO时间范围 暂时写死 时间范围为当前时间-时间粒度 * queryCount
    //已处理的请求统计数据
    let [handledRequestData, setHandledRequestData] = React.useState([]);

    //echart ref
    let echartRef = React.useRef(null);
    let echartWrapperRef = React.useRef(null);

    useEffect(() => {
        // setLoading(true);
        function fetchData(firstTime) {
            let url = `${remote}/getHandledRequestCountByTimeGranularity`;
            //方式为jsonp
            let sendTime = new Date().getTime()
            axios({
                url: url,
                params: {
                    timeGranularity: timeGranularity,
                    end: sendTime,
                    start: sendTime - timeGranularity * queryCount
                },
                adapter: jsonpAdapter
            }).then(res => {
                let data = res.data;
                //映射成图表数据
                let endTime = parseInt(sendTime / timeGranularity) * timeGranularity;
                let startTime = endTime - timeGranularity * queryCount;
                //生成一个时间序列 长度为queryCount
                let timeSeries = [];
                for (let i = 0; i < queryCount; i++) {
                    timeSeries.push({
                        name: moment(new Date(startTime + (i + 1) * timeGranularity)).format("YYYY-MM-DD HH:mm:ss"),
                        successCount: 0,
                        failCount: 0,
                    });
                }

                data.forEach(item => {
                    //计算出当前数据在时间序列中的索引
                    let index = parseInt((item.time - startTime) / timeGranularity) - 1;
                    console.log(index);
                    //检查索引是否合法
                    if (index < 0 || index >= queryCount) {
                        return;
                    }

                    if (item.status === 1) {
                        timeSeries[index].successCount = item.count;
                    } else {
                        timeSeries[index].failCount = item.count;
                    }
                });
                var option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: [
                            {
                                name: '成功数',
                                textStyle: {
                                    color: '#62D96B'
                                }
                            },
                            {
                                name: '失败数',
                                textStyle: {
                                    color: '#BB0065'
                                }
                            }
                        ]
                    },
                    xAxis: {
                        data: timeSeries.map(item => item.name)
                    },
                    yAxis: {},
                    series: [
                        {
                            name: '成功数',
                            type: 'line',
                            data: timeSeries.map(item => item.successCount),
                            lineStyle: {
                                color: "#62D96B"
                            }
                        },
                        {
                            name: '失败数',
                            type: 'line',
                            data: timeSeries.map(item => item.failCount),
                            lineStyle: {
                                color: "#BB0065"
                            }
                        }
                    ]
                };

                if (firstTime) {
                    option.legend.selected = {
                        "成功数": true,
                        "失败数": false
                    }
                }
                // 使用刚指定的配置项和数据显示图表。
                echartRef.current.setOption(option);

                setLoading(false);

            }).catch(err => {
                console.error(err);
            });
        }

        let timer = setInterval(fetchData, updateFrequency);
        fetchData(true);
        return () => {
            clearInterval(timer);
        }

    }, [remote, version, updateFrequency, timeGranularity, queryCount]);


    useEffect(() => {
        let wrapperHeight = echartWrapperRef.current.clientHeight;
        let wrapperWidth = echartWrapperRef.current.clientWidth;
        echartRef.current = echarts.init(echartWrapperRef.current);


    }, []);


    //header高70px 
    return <>
        <div style={{ maxWidth: 1550, margin: "auto", paddingTop: 30 }}  >
            <div style={{ display: "flex", flexDirection: "row" }}>
                <FormGroup style={{ width: 100 }} >
                    <Label>时间粒度</Label>
                    <HTMLSelect value={timeGranularity} onChange={e => { setTimeGranularity(e.target.value) }}>
                        {
                            TIME_GRANULARITY_CONFIGS.map(item => {
                                return <option key={item.value} value={item.value}>{item.label}</option>
                            })
                        }
                    </HTMLSelect>
                </FormGroup>

                <FormGroup style={{ width: 100 }} >
                    <Label>更新频率</Label>
                    <HTMLSelect value={updateFrequency} onChange={e => { setUpdateFrequency(e.target.value) }}>
                        {
                            UPDATE_FREQUENCY_CONFIGS.map(item => {
                                return <option key={item.value} value={item.value}>{item.label}</option>
                            })
                        }
                    </HTMLSelect>
                </FormGroup>

                <FormGroup style={{ width: 100 }} >
                    <Label>查询条数</Label>
                    <HTMLSelect value={queryCount} onChange={e => { setQueryCount(e.target.value) }}>
                        {
                            QUERY_COUNT_CONFIGS.map(item => {
                                return <option key={item.value} value={item.value}>{item.label}</option>
                            })
                        }
                    </HTMLSelect>
                </FormGroup>
            </div>
        </div>
        {
            loading && <Spinner style={{ marginTop: 120 }} />
        }
        <div style={{ width: "100%", height: "calc(100% - 230px)", marginTop: 0 }} ref={echartWrapperRef}>

        </div>
    </>
}