import { Button, ControlGroup, InputGroup, Label, TextArea } from "@blueprintjs/core";
import React from "react"
import robotParser from "robots-parser"
import useLocalStorage from "../Common/useLocalStorage";

export default function Robots() {
    let [url, setUrl] = useLocalStorage("ROBOTS_URL", "");
    let [robotsTxt, setRobotsTxt] = useLocalStorage("ROBOTS_TXT", "");

    let robotsTxtUrl = null;
    let result = null;
    try {
        robotsTxtUrl = new URL(url).origin + "/robots.txt";
        if (robotsTxt.trim()) {
            let r = robotParser(robotsTxtUrl, robotsTxt);
            result = r.isAllowed(url);
        }
    } catch (error) {

    }

    return <div style={{ width: 800, margin: "auto", paddingTop: 100 }} >

        <div style={{ height: 100 }}>
            <div style={{ color: "yellow" }}>
                结果:{result === null ? "待计算" : <span style={{ color: (result ? "green" : "red") }}>{(result ? "允许" : "禁止")}</span>}
            </div>
            <div>
                <a href={robotsTxtUrl} target="_blank">{robotsTxtUrl}</a>
            </div>
        </div>

        <Label>抓取网页地址</Label>
        <ControlGroup
            vertical={false}>
            <InputGroup value={url} id="url" style={{ width: 800 }} placeholder="输入网页地址" onChange={e => { setUrl(e.target.value) }} />
            {/* <Button icon="key-enter" onClick={() => gotoMain()} /> */}
        </ControlGroup>
        <div style={{ marginTop: 20 }}></div>
        <Label>robots.txt内容</Label>
        <ControlGroup
            vertical={false}>
            <TextArea value={robotsTxt} style={{ width: 800, height: 500 }} onChange={e => { setRobotsTxt(e.target.value) }} />
        </ControlGroup>

    </div>
}