
import ReactJson from 'react-json-view'
import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import HtmlPreview from '../HtmlPreview';

// import { dark } from 'react-syntax-highlighter/dist/esm/light/';

const cheerio = require('cheerio');
let axios = require('axios');
let jsonpAdapter = require('axios-jsonp');
var formatter = require('html-formatter');



export default function ({ }) {
    let { remote, version, key, type } = useParams();

    let [data, setData] = React.useState("downloading....");
    let [err, setErr] = React.useState("");

    React.useEffect(() => {
        (async () => {
            let res = await axios({
                url: `${remote}/preview`,
                params: {
                    file: key
                }
            })
            let data = res.data;
            if (type === "json") {
                try {
                    setData(JSON.parse(data));
                } catch (error) {
                    setErr(JSON.stringify(error));
                }
            } else if (type === "html") {
                let str = formatter.render(data);
                // let str = cheerio.load(data).root().html()
                setData(str);
            } else {
                setData(data);
            }
        })();
    }, [key]);

    return <div style={{ backgroundColor: "#2F343C", left: 0, right: 0, top: 40, bottom: 0, position: "absolute", overflow: "scroll" }} className='bp4-dark'>
        <div style={{ height: "100%", width: "100%" }} >
            {
                type === "text" && <SyntaxHighlighter style={vscDarkPlus} showLineNumbers={true} language="textfile" wrapLines={true} wrapLongLines={false}>{data}</SyntaxHighlighter>
            }
            {
                type === "json" && <ReactJson displayDataTypes={false} theme="colors" enableClipboard={false} src={data} />
            }
            {
                type === "html" && <HtmlPreview data={data} />
            }
        </div>
    </div>
}