import React, { useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Allotment } from "allotment";
import { Breadcrumb, Breadcrumbs, Button, Classes, ControlGroup, Drawer, FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import useLocalStorage from "../Common/useLocalStorage";
import _ from 'lodash';
import "allotment/dist/style.css";
import { useState } from 'react';
const cheerio = require('cheerio');

window.cheerio = cheerio;



export default function HtmlPreview({ data }) {
    const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
    let document = React.useRef(null);
    let [selector, setSelector] = useLocalStorage("HTML_PREVIEW_SELECTOR", "h1");
    useEffect(() => {
        let doc = cheerio.load(data);
        console.log(doc);
        document.current = doc;
        forceUpdate();
    }, [data]);

    let selectResult = null;
    let error = null;
    if (document.current) {
        let $ = document.current;
        try {
            selectResult = $(selector);
        } catch (e) {
            error = e;
        }
    }

    return <Allotment  >
        <Allotment.Pane >
            <div style={{ overflow: "auto", width: "100%", height: "100%" }}>
                <SyntaxHighlighter style={vscDarkPlus} showLineNumbers={true} language="cshtml" wrapLines={true} wrapLongLines={false}>{data}</SyntaxHighlighter>
            </div>
        </Allotment.Pane>
        <Allotment.Pane preferredSize={600}>
            <Allotment vertical={true}>
                <Allotment.Pane preferredSize={200}>
                    <div style={{ height: "100%" }}>
                        <TextArea className={Classes.FILL} style={{ resize: "none", height: "100%" }} value={selector} onChange={e => { setSelector(e.target.value) }} />
                    </div>
                </Allotment.Pane>
                <Allotment.Pane>
                    <div style={{ height: "100%", padding: 20 }}>
                        {error && <div>{error.message}</div>}
                        {selectResult && <div>
                            <SyntaxHighlighter style={vscDarkPlus} language="javascript" wrapLines={true} wrapLongLines={false}>{`$("${_.replace(selector, /"/g, "\\\"")}").text()`}</SyntaxHighlighter>
                            <hr />
                            <p><strong>数量: </strong>{selectResult.length}</p>
                            <hr />
                            <p><strong>文本: </strong>{selectResult.text()}</p>

                        </div>}
                    </div>
                </Allotment.Pane>
            </Allotment>
        </Allotment.Pane>
    </Allotment>
}