import { Button, ControlGroup, FormGroup, InputGroup, useHotkeys } from '@blueprintjs/core';
import * as React from 'react';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import { DataGrid } from "@mui/x-data-grid"; 
function Index({ }) {
    let navigate = useNavigate();
    let [apiAddress, setApiAddress] = React.useState("");

    async function gotoMain() {
        // alert(1);
        try {
            apiAddress = apiAddress.trim();
            if (!apiAddress.startsWith("http://") && !apiAddress.startsWith("https://")) {
                throw new Error("Invalid API address");
            }
            navigate(`/RequestQueue/${encodeURIComponent(apiAddress)}/1/`);
        } catch (error) {
            console.error(error);
            alert("API地址不合法,请检查");
        }
    }
    React.useEffect(() => {
        document.title = `RequestQueue`;
    }, []);

    return <div style={{ width: 500, margin: "auto", paddingTop: 200 }} >
        <FormGroup
            label="API地址"
            labelFor="apiAddress"
        >
            <ControlGroup
                vertical={false}>
                <InputGroup value={apiAddress} id="apiAddress" style={{ width: 380 }} placeholder="输入API地址" onChange={e => { setApiAddress(e.target.value) }} />
                <Button icon="key-enter" onClick={() => gotoMain()} />
            </ControlGroup>
        </FormGroup>
    </div>
}

export default React.memo(Index);