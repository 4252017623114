
import { Breadcrumbs, Spinner } from '@blueprintjs/core';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useVirtual } from 'react-virtual';
import { parseDirPath, parseFilePath } from "./PathTool";
export default function ({ files, loading }) {

    let { remote, version, currentPath, currentSearch } = useParams();
    const parentRef = React.useRef()
    const rowVirtualizer = useVirtual({
        size: files.length,
        parentRef,
        estimateSize: React.useCallback(() => 20, []),
    });

    return <>
        <div
            ref={parentRef}
            style={{
                height: `100%`,
                overflow: 'auto',
            }}
        >
            <div
                style={{
                    height: `${rowVirtualizer.totalSize}px`,
                    width: '100%',
                    position: 'relative',
                }}
            >
                {
                    loading && <div style={{ marginTop: 100 }}><Spinner /></div>
                }
                {!loading && rowVirtualizer.virtualItems.map(virtualRow => {
                    let file = files[virtualRow.index];
                    let isDir = file.size === null;
                    let { fileName } = isDir ? parseDirPath(file.fullPath) : parseFilePath(file.fullPath);

                    return (
                        <div
                            key={virtualRow.index}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualRow.size}px`,
                                transform: `translateY(${virtualRow.start}px)`,
                            }}
                        >
                            <span title={file.fullPath}>
                                {fileName}
                            </span>
                            &nbsp;
                            {
                                isDir && <Link to={`/SFS/${encodeURIComponent(remote)}/${encodeURIComponent(version)}/${encodeURIComponent(file.fullPath)}/${encodeURIComponent(currentSearch)}/`}>打开文件夹</Link>
                            }
                            {!isDir && <>
                                <a href={`${remote}/download?key=${encodeURIComponent(files[virtualRow.index].fullPath)}`}>
                                    下载
                                </a>
                                &nbsp;
                                <Link to={`preview/text/${encodeURIComponent(files[virtualRow.index].fullPath)}`}>
                                    以纯文本预览
                                </Link>
                                &nbsp;
                                <Link to={`preview/json/${encodeURIComponent(files[virtualRow.index].fullPath)}`}>
                                    以JSON预览
                                </Link>
                                &nbsp;
                                <Link to={`preview/html/${encodeURIComponent(files[virtualRow.index].fullPath)}`}>
                                    以HTML预览
                                </Link>
                                {/* &nbsp;
                                <Link to={`extract/html/${encodeURIComponent(files[virtualRow.index].fullPath)}`}>
                                    元素提取工具
                                </Link> */}
                            </>}
                        </div>
                    )
                })}
            </div>
        </div>
    </>
}