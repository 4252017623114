import { BrowserRouter, HashRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import SFSMain from './SFS/SFSMain';
import * as React from 'react';
import Preview from './SFS/Preview';
import ExtractHtml from './SFS/ExtractHtml';
import "./App.css";
import Header from './Common/Header';
import SFS from './SFS/SFS';
import PreviewKotlin from "./SFS/Kotlin/PreviewKotlin";
import Robots from './Robots/Robots';
import HttpLog from "./HttpLog/Index";
import HttpLogMain from "./HttpLog/Main";
import RequestQueueIndex from "./RequestQueue/Index";
import RequestQueueMain from "./RequestQueue/Main";
import SFSKotlinMain from './SFS/Kotlin/SFSKotlinMain';

export default function App() {
  return (
    <div className='bp4-dark' style={{ backgroundColor: "#2F343C", position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/SFS/:remote/:version/"
            element={<Navigate to={`./${encodeURIComponent("/")}/${encodeURIComponent(" ")}/`} replace />}
          />
          <Route path='/SFS/:remote/:version/:currentPath/:currentSearch/' element={<SFSMain />} ></Route>
        </Routes>
      </BrowserRouter>
      <HashRouter>
        <Header />
        <Routes>
          <Route
            path='/SFS/'
            element={<SFS />}
          />
          <Route
            path='/SFS/kotlin/'
            element={<SFS uiVersion="kotlin" />}
          />
          <Route
            path='/Robots/'
            element={<Robots />}
          />

          <Route
            path="/SFS/kotlin/:remote/:version/"
            element={<Navigate to={`./${encodeURIComponent("/")}/${encodeURIComponent(" ")}/`} replace />}
          />

          <Route
            path="/SFS/:remote/:version/"
            element={<Navigate to={`./${encodeURIComponent("/")}/${encodeURIComponent(" ")}/`} replace />}
          />

          <Route path='/SFS/kotlin/:remote/:version/:currentPath/:currentSearch/' element={<SFSKotlinMain />} >
            <Route path="preview/:type/:key" element={<PreviewKotlin />} />
          </Route>

          <Route path='/SFS/:remote/:version/:currentPath/:currentSearch/' element={<SFSMain />} >
            <Route path="preview/:type/:key" element={<Preview />} />
          </Route>

          <Route path='/SFS/:remote/:version/extract/html/:key' element={<ExtractHtml />} />


          <Route
            path='/HttpLog/'
            element={<HttpLog />}
          />
          <Route
            path="/HttpLog/:remote/:version/"
            element={<HttpLogMain />}
          />
          <Route
            path="/RequestQueue/"
            element={<RequestQueueIndex />}
          />
          <Route
            path="/RequestQueue/:remote/:version/"
            element={<RequestQueueMain />}
          />


        </Routes>
      </HashRouter>
    </div>
  )
}