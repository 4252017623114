import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { Collapse } from "@blueprintjs/core";
import moment from 'moment';

function RequestResponse({ request, response }) {
    let requestHeaders = request?.headers?.headers || request?.headers || {};
    let responseHeaders = response?.headers.headers || response?.headers || {};
    let time = moment(request._ts).format("YYYY-MM-DD HH:mm:ss");
    let timeDiff = -1;
    if (response) {
        timeDiff = (response._ts - request._ts) / 1000;
    }
    let statusCode = response?.status || response?.statusCode;
    let [open, setOpen] = useState(false);

    return <div>
        <span onClick={(e) => { setOpen(!open) }} style={{ color: response ? "#62D96B" : "#FBD065" }}>{`[${time}] ${timeDiff > 0 ? `耗时:${timeDiff}s` : ``} [${request.method}] ${request.url}`}</span>
        <Collapse isOpen={open}>
            {
                open && <div>
                    <div>请求方法: {request.method}</div>
                    <div>请求地址: <a target="_blank" href={request.url}>{request.url}</a></div>
                    {
                        request._crawleeRequest
                        && <div>
                            <div>Crawlee 数据:
                                {JSON.stringify(request._crawleeRequest)}
                            </div>
                            <div>Crawlee UserData:
                                {JSON.stringify(request._crawleeRequest.userData)}
                            </div>
                        </div>
                    }
                    {
                        request.userData
                        && <div>
                            <div>用户数据:
                                {JSON.stringify(request.userData)}
                            </div>
                        </div>
                    }
                    <div>请求头:</div>
                    {
                        (Object.keys(requestHeaders)).map((key, index) => {
                            return <div key={index}><span style={{ color: "#D69FD6" }}>{key}:</span> {requestHeaders[key]}</div>
                        })
                    }
                    <div>请求体: {request.body}</div>
                    {
                        response && <>
                            <hr />
                            <div>响应码: {statusCode}</div>
                            <div>响应头:</div>
                            {
                                (Object.keys(responseHeaders)).map((key, index) => {
                                    return <div key={index}><span style={{ color: "#D69FD6" }}>{key}:</span> {responseHeaders[key]}</div>
                                })
                            }
                            <div>响应体: </div>
                            <div>{response.body}</div>
                        </>
                    }
                </div>
            }
        </Collapse>

    </div>

    // return <Collapsible trigger={<span style={{ color: response ? "#62D96B" : "#FBD065" }}>{`[${time}] ${timeDiff > 0 ? `耗时:${timeDiff}s` : ``} [${request.method}] ${request.url}`}</span>}  >
    //     <div>请求方法: {request.method}</div>
    //     <div>请求地址: <a target="_blank" href={request.url}>{request.url}</a></div>
    //     {
    //         request._crawleeRequest
    //         && <div>
    //             <div>Crawlee 数据:
    //                 {JSON.stringify(request._crawleeRequest)}
    //             </div>
    //             <div>Crawlee UserData:
    //                 {JSON.stringify(request._crawleeRequest.userData)}
    //             </div>
    //         </div>
    //     }
    //     <div>请求头:</div>
    //     {
    //         (Object.keys(requestHeaders)).map((key, index) => {
    //             return <div key={index}><span style={{ color: "#D69FD6" }}>{key}:</span> {requestHeaders[key]}</div>
    //         })
    //     }
    //     <div>请求体: {request.body}</div>
    //     {
    //         response && <>
    //             <hr />
    //             <div>响应码: {statusCode}</div>
    //             <div>响应头:</div>
    //             {
    //                 (Object.keys(responseHeaders)).map((key, index) => {
    //                     return <div key={index}><span style={{ color: "#D69FD6" }}>{key}:</span> {responseHeaders[key]}</div>
    //                 })
    //             }
    //             <div>响应体: </div>
    //             <div>{response.body}</div>
    //         </>
    //     }
    // </Collapsible>
};

export default React.memo(RequestResponse);