
import * as React from 'react';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Breadcrumbs, Button, Classes, ControlGroup, Drawer, FormGroup, InputGroup } from '@blueprintjs/core';
import FileList from './FileList';
import Pager from './Pager';

let axios = require('axios');
let jsonpAdapter = require('axios-jsonp');

// Somewhere in your `index.ts`: 
let timerIdMap = {};
let callIdMap = {};


function SFSKotlinMain({ }) {
    let navigate = useNavigate();

    if (!window.location.hash.startsWith('#/')) {
        navigate("/#" + window.location.pathname);
        window.location.reload();
    }
    let pageMode = "page";
    let { remote, version, currentPath, currentSearch, type, key } = useParams();
    let [files, setFiles] = React.useState(null);
    let [dirs, setDirs] = React.useState(null);
    let [filePageNum, setFilePageNum] = React.useState(1);
    let [dirPageNum, setDirPageNum] = React.useState(1);
    let [dirInfo, setDirInfo] = React.useState(null);
    let [fsInfo, setFsInfo] = React.useState(null);
    let pageSize = 200;
    let [init, setInit] = React.useState(true);
    let [searchParams, setSearchParams] = useSearchParams();
    function apiHttpCall(api, params, callback, timeout = 1000) {
        let typeKey = api + params.listType;
        let timer = timerIdMap[typeKey];

        clearTimeout(timer);
        callIdMap[typeKey] = (callIdMap[typeKey] || 0) + 1;

        let thisCallId = callIdMap[typeKey];
        timerIdMap[typeKey] = setTimeout(async () => {
            let res = await axios({
                url: `${remote}/${api}`,
                params: params
            })
            if (thisCallId === callIdMap[typeKey]) {
                callback(res.data);
            }
        }, timeout);
    }

    let realSearch = currentSearch.substring(1);

    function updateDirInfo(currentPath) {
        setDirInfo(null);
        apiHttpCall("getDirectoryInfo", {
            path: currentPath
        }, (data) => {
            setDirInfo(data);
        }, 0);
    }

    function updateFsInfo() {
        setFsInfo(null);
        apiHttpCall("getFileSystemInfo", {}, (data) => {
            setFsInfo(data);
        }, 0);
    }

    function updateFiles() {
        setFiles(null);
        apiHttpCall("list", { path: currentPath, pageMode, offset: (filePageNum - 1) * pageSize, limit: pageSize }, (data) => {
            setFiles(data);
        });
    }
    function updateDirs() {
        setDirs(null);
        apiHttpCall("list", { path: currentPath, pageMode, listType: "directory", offset: (dirPageNum - 1) * pageSize, limit: pageSize }, (data) => {
            setDirs(data);
        });
    }




    function startSearch() {
        if (realSearch) {
            apiHttpCall("searchFiles", { search: realSearch }, (data) => {
                setFiles(data);
            }, init ? 0 : 1000);
        } else {
            updateFiles();
            apiHttpCall("list", {
                parentPath: currentPath,
                listType: "directory"
            }, (data) => {
                setDirs(data);
            }, 0);

        }
        setInit(false);
    }
    React.useEffect(() => {
        document.title = `SFS Kotlin ${remote}`;
        updateDirInfo(currentPath);
        updateFsInfo();
    }, [currentPath]);

    React.useEffect(() => {
        updateFiles();
    }, [filePageNum, currentPath]);

    React.useEffect(() => {
        updateDirs();
    }, [dirPageNum, currentPath]);

    let allPath = currentPath.split("/").filter(s => s !== "");

    let breadcrumbsList = [{
        icon: "folder-open", text: "[根目录]", path: ""
    }];

    function renderBreadcrumb(props) {
        return <Breadcrumb {...props} onClick={(e) => {
            console.log(props.path)
        }} />
    }

    breadcrumbsList = [...breadcrumbsList, ...allPath.map((p, index) => {
        function toPath() {
            let str = "";
            for (let i = 0; i <= index; i++) {
                str += ("/" + allPath[i]);
            }
            return str;
        }
        return {
            icon: "folder-open", text: p, path: toPath()
        }
    })];

    return <div style={{ padding: 50, position: "absolute", left: 0, right: 0, top: 30, bottom: 30 }}>
        <p>
            api address: {remote} version: {version}
        </p>
        <hr />
        {/* <FormGroup
            label="文件名搜索 (文件多时性能较差) (输入停止1S后开始搜索)"
            labelFor="fileName"
        >
            <ControlGroup
                vertical={false}>
                <InputGroup id="fileName" style={{ width: 380 }} placeholder="文件名" value={realSearch} onChange={e => {
                    navigate(`/SFS/${encodeURIComponent(remote)}/${encodeURIComponent(version)}/${encodeURIComponent(currentPath)}/${encodeURIComponent(" " + e.target.value)}/`);
                }} />
                <Button icon="refresh" onClick={() => startSearch()} />
            </ControlGroup>
        </FormGroup> */}
        {currentPath}
        {/* <Breadcrumbs items={breadcrumbsList} currentBreadcrumbRenderer={renderBreadcrumb} /> */}
        {
            currentPath !== "/" && <Button onClick={() => {
                let newPath = currentPath.split("/").slice(0, -1).join("/");
                navigate(`/SFS/kotlin/${encodeURIComponent(remote)}/${encodeURIComponent(version)}/${encodeURIComponent(newPath)}/`)
            }}>返回上一级</Button>
        }


        <div style={{ position: "absolute", left: 50, right: 0, top: 205, bottom: 30 }}>
            <div>
                <p>{`路径 ${currentPath} 下 一共包含文件数: ${(dirInfo || { fileCount: "?" }).fileCount} 文件夹数: ${(dirInfo || { directoryCount: "?" }).directoryCount}`}  {` 整个文件系统下 总文件数: ${fsInfo?.fileCount || "?"} 总文件夹数: ${fsInfo?.directoryCount || "?"}`} </p>
            </div>

            <FileList files={dirs || []} loading={dirs === null} />
            <FileList files={files || []} loading={files === null} />
            <Pager page={dirPageNum} total={Math.ceil((dirInfo?.directoryCount || 0) / pageSize)} onPageChange={(page) => {
                setDirPageNum(page);
            }} />
            <Pager page={filePageNum} total={Math.ceil((dirInfo?.fileCount || 0) / pageSize)} onPageChange={(page) => {
                setFilePageNum(page);
            }} />


        </div>


        {
            <Drawer icon="document"
                className={Classes.DARK}
                onClose={() => {
                    navigate(`/SFS/kotlin/${encodeURIComponent(remote)}/${encodeURIComponent(version)}/${encodeURIComponent(currentPath)}/${encodeURIComponent(" " + realSearch)}/`)
                }}
                autoFocus={true} isOpen={!!type} title={key} size="100%">
                <Outlet />
            </Drawer>
        }
    </div>
}

export default React.memo(SFSKotlinMain);