import { Alignment, Button, Classes, Navbar, NavbarDivider } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";

export default function () {
    let navigate = useNavigate();
    return <Navbar  >
        <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>RINGO UI</Navbar.Heading>
            <NavbarDivider />
            <Button className={Classes.MINIMAL} text="Robots.txt检查工具" onClick={() => { navigate("/Robots/") }} />
            <Button className={Classes.MINIMAL} text="SFS" onClick={() => { navigate("/SFS/") }} />
            <Button className={Classes.MINIMAL} text="HttpLog" onClick={() => { navigate("/HttpLog/") }} />
        </Navbar.Group>
    </Navbar>
}